














































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import MQTT from '@/modules/MQTT';
import { Reports, User, Global } from '@/store';
import API from '@/modules/API';
import Vue2TouchEvents from 'vue2-touch-events';
import Utils from '../../../modules/Utils';

Vue.use(Vue2TouchEvents);

@Component({})
export default class ControlSliderTab extends Vue {
  @Reports.State('reportsList') devices;
  @Reports.State('devicesChangedFromMqtt') devicesChangedFromMqtt;
  @Reports.Getter('commissionedFixtures') commissionedFixtures;
  @Reports.Mutation('removeDeviceChangedFromMqtt') removeDeviceChangedFromMqtt;
  @User.State('project') project;
  @Global.State('readonly_user') readonly_user;

  @Prop() group;

  streamPower = {
    value: 0
  };

  isDisabled = false;

  mqtt = MQTT.instance;
  sliderValue = 0;
  percentToDaliTable = null;
  daliToPercentTable = {};
  converted = false;
  loading = true;

  async mounted() {
    this.loading = true;
    if (this.group.devices.length){
      [this.converted, this.percentToDaliTable, this.daliToPercentTable] = await Utils.convertDimmerValues(this.group.devices[0]);
      await this.fetchStreams(this.group.devices[0]);
    }
    this.loading = false;
  }

  get totalDevices(){
    return this.commissionedFixtures.filter((device) => this.group.devices.includes(device.id)).length;
  }

  @Watch('devices', { immediate: true, deep: true })
  updateDevicesWithMqttData(){
    [...this.devicesChangedFromMqtt.values()].forEach((device) => {
      this.$root.$refs.GoogleMap['updateFeatureIcon'](device);
      this.removeDeviceChangedFromMqtt(device.id);
    });
  }

  getPercentValue() {
    return this.streamPower && this.streamPower.value
      ? Utils.getPercentValue(this.converted, this.daliToPercentTable, this.streamPower.value)
      : 0;
  }

  async getStream(id) {
    const res = await API.get('devices', id + '/streams/', {});
    return res.results.find((r) => r.name === 'flushLoraBroadcast');
  }

  async fetchStreams(id) {
    const res = await API.get('devices', id + '/streams/', {});
    this.streamPower = res.results.find((r) => r.name === 'power');
    if (this.streamPower) {
      this.sliderValue = this.getPercentValue();
    }
    
    if (this.streamPower && this.streamPower.value >= 0) {
      this.mqtt.listen(
        `cot-v4/group/${this.group.id}/cmd/power/fmt/minimal`,
        (msg, match) => {
          const string = new TextDecoder('utf-8').decode(msg);
          this.streamPower.value = Number(string);
          this.sliderValue = this.getPercentValue();
        }
      );
    }
  }

  async setOverride() {
    if (this.group) {
      const streamValue = this.converted ? this.percentToDaliTable[this.sliderValue] : Math.round((this.sliderValue * 254) / 100);
      const options = {
        retain: false,
        qos: 1
      };
      if (this.streamPower){
        this.isDisabled = true;
        this.publishMqtt(streamValue, options);
        setTimeout(() => {
          this.publishMqtt(streamValue, options);
          setTimeout(() => {
            this.publishMqtt(streamValue, options);
            setTimeout(() => {
              this.isDisabled = false;
            }, 5000);
          }, 5000);
        }, 5000);
      }
    }
  }

  publishMqtt(streamValue, options) {
    this.mqtt.publish(
      // @ts-ignore
      `cot-v4/user/${this.$store.state.User.user_id}/group/${this.group.id}/cmd/power/fmt/minimal`,
      streamValue + '',
      options
    );
  }

  turnOnOff() {
    this.sliderValue = this.sliderValue > 0 ? 0 : 100;
    this.setOverride();
  }
}
